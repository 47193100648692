(function() {
    let checkElementCount = 0;
    const elementIntervalChecker = setInterval(checkPageElement, 1500);

    function checkPageElement() {
        const containerElement = document.querySelector('header .container');
        const loaderElement = document.querySelector('.loading-container .loader');

        if (containerElement !== null || loaderElement !== null) {
            clearInterval(elementIntervalChecker);
            checkElementCount = 0;
            return;
        }

        checkElementCount++;

        if (checkElementCount === 3) {
            clearInterval(elementIntervalChecker);
            dataLayer.push({'event': 'pageContentMissing'});
        }
    }
})();
